import { useNavigate, useParams } from "react-router-dom"
import { Button, Card, CardContent, Container, Grid, List, ListItem, ListItemText, Table } from "@mui/material"
import axios from "axios"
import { useEffect, useState } from "react"
import { Figure, FigureImage } from "react-bootstrap"

function OrderDetails() {
    const [orderDetails, setOrderDetails] = useState({})
    const { orderId } = useParams();
    const navigate = useNavigate();
    const [detailedOrdersList, setDetailedOrdersList] = useState([])

    const getOrderDetailsData = () => {
        const inputBody = {
            "main_id": "8989877hhftgh67",
            "order_id": orderId
        };
        axios.post('https://asvmall.com/api/orderDetails.php', inputBody).then(res => {
            //console.log("detail", res.data.length)
            if (!(res.data === 404)) {
                setDetailedOrdersList(res.data)
                setOrderDetails(res.data[0])
            }
        }).catch(e => {
            setDetailedOrdersList([])
            //console.log("Error occured while geting order details", e)
        })
    }

    useEffect(() => {
        getOrderDetailsData()
    }, [])

    const onProductItemClick = (productID) => {
        navigate(`/products/${productID}`)
    }

    return (<div>
        <Container component={"main"} maxWidth="md">
            {detailedOrdersList.length < 1 ?
                <div className="d-flex  flex-column justify-content-center align-items-center">
                    <img className="w-75" src="https://www.breathearomatherapy.com/assets/images/global/no-product.png" />
                    <p className="text-center text-danger">No Order Details Found for the Given URL</p>
                </div> :
                <Card >
                    <CardContent>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <h4>Address:</h4>
                                <List>
                                    <ListItem>
                                        <ListItemText>{"Mr." + orderDetails.OrderShipName + "," + orderDetails.OrderShipAddress + ","}</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>{orderDetails.OrderShipAddres2 + "," + orderDetails.OrderCity + ","}</ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText>{orderDetails.OrderState + "," + orderDetails.OrderZip}</ListItemText>
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={6}>
                                <h4>Order Price:</h4>
                            </Grid>
                            <Grid item xs={6}>
                                <p>{orderDetails.OrderAmount}</p>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                        </Grid>
                        <Card className="m-auto">
                            <CardContent item xs={12} sm={6} md={3}>
                                <h5>Order track details:</h5>
                                <Table>
                                    <div>
                                        <div className="">
                                            {detailedOrdersList.map(
                                                (eachItem) =>
                                                    <Grid key={eachItem.productID} container spacing={2} align={'center'} direction="row"
                                                        justifyContent="center"
                                                        alignItems="center" >
                                                        <Grid item xs={12} md={4}>
                                                            <Figure className="rounded border border-light p-2">
                                                                <FigureImage width={200} height={150} src={eachItem.productImage}></FigureImage>
                                                            </Figure>
                                                        </Grid>
                                                        <Grid item xs={12} md={4} className="text-start">
                                                            <h5 className="p-1">{eachItem.DetailName}</h5>
                                                            <h6 > Rs:{eachItem.DetailPrice}/-     <p >Quantity:{eachItem.DetailQuantity}</p></h6>
                                                        </Grid>
                                                        <Grid item xs={12} md={4}>
                                                            <Button variant="contained" onClick={() => onProductItemClick(eachItem.productID)}>Product Details</Button>
                                                        </Grid>
                                                    </Grid>
                                            )
                                            }
                                        </div>
                                    </div>
                                </Table>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
            }
        </Container>
    </div>)
}
export default OrderDetails
