import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import axios from 'axios';
import RecipeReviewCard from '../products/products';
import { useNavigate } from 'react-router-dom';
import SaveForLaterProductCard from './saveforlaterproductcard';

const SaveForLater = () => {
    const [sellerList, setSellerList] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedcategoryId, setSelectedcategoryId] = useState(1)
    const [selectedcategories, setSelectedcategories] = useState([])
    const [saveforLaterList, setSaveForLaterList] = useState([]);
    const navigate = useNavigate();
    const handleListItemClick = (event, index, each) => {
        setSelectedIndex(index);
        onCategoryClick(each)
    }

    const onCategoryClick = (eachCat) => {
        setSelectedcategoryId(eachCat.seller_id)
        getSubCatItems(eachCat.seller_id);
    }

    const getSubCatItems = (catId) => {
        axios.post('https://asvmall.com/api/saveforlater/getsaveforlateritems.php', {
            "main_id": "8989877hhftgh67",
            "user_id": catId
        }).then(result => {
            setSelectedcategories(result.data)
        }).catch(e => {
            //console.log(e)
        })
    }

    React.useEffect(() => {
        if (localStorage.getItem("userid")) {
            axios.post('https://asvmall.com/api/saveforlater/getsaveforlateritems.php', {
                "main_id": "8989877hhftgh67",
                "user_id": localStorage.getItem("userid")
            }).then(result => {
                if (Array.isArray(result.data)) {
                    setSaveForLaterList(result.data)
                } else {
                    alert("internal Error, save foe later items list is not fetched properly")
                }
            }).catch(e => {
                //console.log(e)
            })
        } else {
            navigate("/login")
        }
    }, [])


    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    Items You previously added in your cart
                </Grid>
                <Grid item xs={12} sx={{ flexGrow: 1, }} className="d-flex flex-wrap">
                    {
                        saveforLaterList.length > 0 ?
                            saveforLaterList.map((each,ind) => (
                                <Grid item key={ind} xs={12} sm={6} md={4} lg={3} xl={2}>
                                    <SaveForLaterProductCard productDetails={each} key={each.productID} />
                                </Grid>
                            )) : <div className="d-flex  flex-column justify-content-center align-items-center">
                                <img className="w-75" src="https://diademstore.b-cdn.net/images/no-result-diadem.png" />
                                <p className="text-center text-danger">No Products found on your save for later bag</p>
                            </div>
                    }
                </Grid>
            </Grid>
        </Box>
    )
}
export default SaveForLater


