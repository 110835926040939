

import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import '../cart/cartlist.css'
//grid imports
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Grid, Container } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import axios from "axios";
import DeleteIcon from '@mui/icons-material/Delete';
import OrderStatusComponent from "./orderStatus";
import MediaControlCard from "./ordersCardExample";

function OrderList() {
   const navigate = useNavigate()
   const [cartItems, setCartItems] = useState([]);
   const [userdetails, setUserDetails] = useState({})
   const [updateUi, setUpdateUi] = useState(false);
   const [currentState, setCurrentState] = useState("LOADING")
   const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
   }));

   const onEachOrderClick = (orderId) => {
      navigate(`/orders/${orderId}`)
   }

   React.useEffect(
      () => {
         if (localStorage.getItem("userid")) {
            const urlInputs = {
               "main_id": "8989877hhftgh67",
               "user_id": localStorage.getItem("userid"),
            }
            axios.post('https://asvmall.com/api/orders.php', urlInputs).then(res => {
               if (Array.isArray(res.data)) {
                  setCurrentState('SUCCESS')
                  setCartItems(res.data)
               } else {
                  setCartItems([])
                  setCurrentState('EMPTY')
               }
            }).catch(e => {
               setCurrentState('FAILED')
               //console.log("Error While fetching data", e)
            })
         } else {
            navigate("/login")
         }
      }, [updateUi]
   )

   switch (currentState) {
      case 'LOADING':
         return (
            <div className="d-flex  align-items-center  justify-content-center text-center">
               <h1>Loading...</h1>
            </div>
         );
         break;
      case 'SUCCESS':
         return (
            // <Container    className="d-flex justify-container-center align-items-center" >
            <Box sx={{ flexGrow: 1, }} elevation={0} className="d-flex justify-content-center">
               {cartItems.length > 0 ?
                  <Grid container spacing={2} xs={12} className="d-flex justify-content-center">
                     <Grid item xs={12} md={8}>
                        <Grid container >
                           <Grid item xs={12} >
                              <Item>
                                 Orders Primary details
                              </Item>
                           </Grid>
                           <Grid item xs={12} >
                              <button className="btn btn-primary" onClick={() => navigate('/')}>
                                 Continue Shopping
                              </button>
                           </Grid>
                        </Grid>
                        <Item sx={{ flexGrow: 1 }}>
                           <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                              {cartItems.map((eachItem, indexnum) => {
                                 return (
                                    <ListItem container key={indexnum} alignItems="flex-start" onClick={() => onEachOrderClick(eachItem.OrderID)}>
                                       <Grid item container alignItems="flex-center" >
                                          <Card sx={{ display: 'flex', flexDirection: 'column', marginBottom: '20px', width: "100%" }}>
                                             <Grid item xs={12} container >
                                                <Grid item xs={12} xs-align="start" sm-align="end" className="d-md-none">
                                                   <Typography component="div" variant="subtitle2">
                                                      <span className="ord-caption">   Order Ammount :</span>
                                                      RS:{eachItem.OrderAmount}/-
                                                   </Typography>
                                                   <Divider component="li" />
                                                </Grid>
                                                <OrderStatusComponent orderStatus={eachItem.orderStatus} orderedOn={eachItem.OrderDate} OrderPreferableTiming={eachItem.OrderPreferableTiming} />
                                             </Grid>
                                             <Box sx={{ display: 'flex', alignItems: 'center', }}>
                                                <CardMedia
                                                   // component="img"
                                                   className="order-thumb"
                                                   image={eachItem.orderThumbnail}
                                                   alt="Live from space album cover"
                                                />
                                                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                   <CardContent sx={{ flex: '1 0 auto' }} className="d-block  d-md-none ">
                                                      <Typography component="div" variant="h6">
                                                         {eachItem.OrderShipName}
                                                      </Typography>
                                                      <Typography variant="subtitle2" component="div">
                                                         {eachItem.OrderShipAddress}, {eachItem.OrderShipAddress2}
                                                      </Typography>
                                                      <Typography variant="subtitle2" component="h2">
                                                         {eachItem.OrderCity}
                                                      </Typography>
                                                      <Typography variant="subtitle2" component="h2">
                                                         {eachItem.OrderState}
                                                      </Typography>
                                                      <Typography variant="subtitle2" component="h2">
                                                         {eachItem.OrderCountry} -{eachItem.OrderZip}
                                                      </Typography>
                                                   </CardContent>
                                                   <CardContent sx={{ flex: '1 0 auto' }} className="d-none d-md-block ps-5">
                                                      <Box sx={{ display: 'flex' }}>
                                                         {/* <Grid item xs={6} xs-align="start" sm-align="end" className="d-sm-block"> */}
                                                         <Typography variant="subtitle1" component="h2">
                                                            Order Ammount:
                                                         </Typography>
                                                         <Typography variant="subtitle1" component="h2" className="text-success ps-3">
                                                            RS:{eachItem.OrderAmount}/-
                                                         </Typography>
                                                         {/* </Grid> */}
                                                      </Box>
                                                      <Divider component="li" />
                                                      <Typography component="div" variant="h5">
                                                         Address
                                                      </Typography>
                                                      <Typography variant="subtitle1" component="div">
                                                         {eachItem.OrderShipName}
                                                      </Typography>
                                                      <Typography variant="subtitle1" component="div">
                                                         {eachItem.OrderShipAddress}, {eachItem.OrderShipAddress2}
                                                      </Typography>
                                                      <Typography variant="subtitle1" component="h2">
                                                         {eachItem.OrderCity}
                                                      </Typography>
                                                      <Typography variant="subtitle1" component="h2">
                                                         {eachItem.OrderState}
                                                      </Typography>

                                                      <Typography variant="subtitle1" component="h2">
                                                         {eachItem.OrderCountry} -{eachItem.OrderZip}
                                                      </Typography>
                                                   </CardContent>
                                                </Box>
                                             </Box>
                                          </Card>
                                       </Grid>
                                    </ListItem>
                                 )
                              })
                              }

                              <Divider component="li" />
                           </List>
                        </Item>
                     </Grid>
                  </Grid>
                  :
                  <Grid container spacing={2} xs={12} elevation={0}>
                     <Grid item xs={12} md={12} elevation={0}>
                        <Item className="emptyCart-bg " >
                           <img className="emptyCart-img w-75" src="https://i.pinimg.com/originals/6f/fd/64/6ffd64c5366898c59bbc91d9aec935c3.png" />
                           <h1>No Orders...</h1>
                        </Item>
                     </Grid>
                  </Grid>}
            </Box>
         )
         break;
      case 'FAILED':
         return (
            <div className="d-flex  m-3  justify-content-center text-center">
               <div>
                  <img className="w-75" src="https://t3.ftcdn.net/jpg/06/47/94/56/360_F_647945631_K1enp9zSJLMLKhzxyArhexgP5xLvDcb6.jpg" alt="server error" />
                  <h1>Internal server error...</h1>
               </div>
            </div>);
      default:
         return (
            <div className="d-flex  m-3  justify-content-center text-center">
               <div>
                  <img className="w-75" src="https://cdni.iconscout.com/illustration/premium/thumb/employee-is-unable-to-find-sensitive-data-9952946-8062130.png" alt="No data found" />
                  <h1>No data found.....</h1>
               </div>
            </div>);
   }
}

export default OrderList

