import { CardContent, Container, FormControl, FormGroup, TextField, Box, Grid, Card, Button, Stack, Link } from "@mui/material"
import axios from "axios"
import { useState } from "react"
import bcrypt from 'bcryptjs'

function ChangePassword() {
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const onOldPAsswordClick = (e) => {
        setOldPassword(e.target.value)
    }

    const validatepasswordsFields = () => {
        let isvalid = true
        if (oldPassword.length < 6) {
            alert("please provide valid credentials length should greater then 6")
            isvalid = false
        } else if (newPassword.length < 6) {
            alert("New passsword length should grater then 6 charecters")
            isvalid = false
        } else if (!(newPassword === confirmPassword)) {
            alert("new password and confirm password is mismatching")
            isvalid = false
        }
        return isvalid
    }

    const onSubmitForm = (e) => {
        const isvalidatepasswordsFields = validatepasswordsFields()
        if (isvalidatepasswordsFields) {
            axios.post("https://asvmall.com/api/verifyuserbyid.php", {
                main_id: "8989877hhftgh67",
                UserId: localStorage.getItem("userid"),
                UserOldPassword: bcrypt.hashSync(oldPassword, '$2a$10$CwTycUXWue0Thq9StjUM0u'),
                UserNewPassword: bcrypt.hashSync(newPassword, '$2a$10$CwTycUXWue0Thq9StjUM0u')
            }).then(
                (result) => {
                    if (result.status == 200 && result.request.readyState == 4) {
                        if (result.data == 1) {
                            alert("Password update Successfull")
                            setOldPassword("")
                            setNewPassword("")
                            setConfirmPassword("")
                        } else {
                            alert("Please provide correct previous password")
                        }
                    } else {
                        alert("please Enter valid Dates")
                    }
                }
            ).catch(
                (err) => {
                })
        }
    }

    return (<div>
        <Container component={"main"} maxWidth="xs">
            <Card>
                <CardContent>
                    <Grid>
                        <Box>
                            <h4>Password Update</h4>
                            <FormGroup>
                                <FormControl><h6>Original Password:</h6></FormControl>
                                <TextField type="password" placeholder="Enter Original Password" value={oldPassword} onChange={(e) => onOldPAsswordClick(e)} />
                            </FormGroup>
                            <FormGroup>
                                <FormControl><h6>New Password:</h6></FormControl>
                                <TextField type="password" placeholder="Enter Original Password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}></TextField>
                            </FormGroup>
                            <FormGroup>
                                <FormControl><h6>Confirm Password:</h6></FormControl>
                                <TextField type="password" placeholder="Enter Original Password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}></TextField>
                            </FormGroup>
                            <Grid>
                                <Stack direction="column" spacing={4} margin={2}>
                                    <Button variant="contained" type="button" onClick={(e) => onSubmitForm(e)}>Submit</Button>
                                </Stack>
                                <Link href='/forgotPassword'>forgot password?</Link>
                            </Grid>
                        </Box>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    </div>)
}
export default ChangePassword;