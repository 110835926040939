import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import bcrypt from 'bcryptjs'

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignIn() {
  const [emailId, setEmailId] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [logginErrors, setLogginErrors] = React.useState({
    show: false,
    message: "Invalid UserName or Password"
  })
  const [errorCodes, setErroCodes] = React.useState({
    emailId: false,
    emailIdCode: "provie valid Email / Email Id",
    password: false,
    passwordCode: "Password should have 6 charecters",
  })

  const onChangeEmail = (e) => {
    const nameRegex = /^[a-zA-Z_0-9@.]+$/;
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
    const isValidEmail = nameRegex.test(e.target.value)
    if (logginErrors.show) {
      setLogginErrors({ show: false, message: "" })
    }

    // 3. Update state and provide feedback based on validation:
    if (e.target.value === "") {
      setEmailId("");
      setErroCodes((prev) => ({ ...prev, emailId: true, emailIdCode: "Email /User Name  is required" }));
    } else if (isValidEmail) {
      if (e.target.value.length < 3) {
        setEmailId(e.target.value.toLowerCase()); // Convert to lowercase (optional)
        setErroCodes((prev) => ({ ...prev, emailId: true, emailIdCode: "User Name / Email Should have 3 charecters atleast" }));
      }
      else if (e.target.value.length >= 20) {
        setErroCodes(prev => ({ ...prev, emailId: true, emailIdCode: "Name Should not greater than 20 charecters" }))
      }
      else {
        setEmailId(e.target.value);
        setErroCodes(prev => ({ ...prev, emailId: false, emailIdCode: "" }))
      }
    } else {
      // setEmailId(e.target.value.toLowerCase()); // Convert to lowercase (optional)
      setErroCodes((prev) => ({ ...prev, emailId: true, emailIdCode: "Invalid email /username format" }));
    }
  };

  const onPasswordChange = (e) => {
    // 3. Update state and provide feedback based on validation:
    if (logginErrors.show) {
      setLogginErrors({ show: false, message: "" })
    }
    if (e.target.value.length < 6) {
      setPassword(e.target.value);
      setErroCodes(prev => ({ ...prev, password: true, passwordCode: "Password Should have atleast 6 charecters" }))
    }
    else if (e.target.value.length > 20) {
      setErroCodes(prev => ({ ...prev, password: true, passwordCode: "Password Should not more than 20 charecters" }))
    }
    else {
      setPassword(e.target.value);
      setErroCodes(prev => ({ ...prev, password: false, passwordCode: "" }))
    }
  }

  const validateRegistrationFeilds = () => {
    let isvalid = true;
    if (emailId.length < 5) {
      setErroCodes(prev => ({ ...prev, emailId: true, emailIdCode: "Enter valid Email address" }))
      isvalid = false
    } if (password.length < 6) {
      setErroCodes(prev => ({ ...prev, password: true, passwordCode: "password Should have 6 charecters atleast" }))
      isvalid = false
    }
    return isvalid
  }

  const navigate = useNavigate()
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const isValide = validateRegistrationFeilds()
    if (isValide) {
      axios.post("https://asvmall.com/api/userlogin.php", {
        main_id: "8989877hhftgh67",
        UserEmail: emailId,
        UserPassword: bcrypt.hashSync(password, '$2a$10$CwTycUXWue0Thq9StjUM0u'),
        // UserEmail: data.get('email'),
        // UserPassword: bcrypt.hashSync(data.get('password'), '$2a$10$CwTycUXWue0Thq9StjUM0u'),
      }).then(
        (result) => {
          if (result.status == 200 && result.request.readyState == 4) {
            if (typeof result.data === "object" && Object.keys(result.data).length > 0) {
              localStorage.setItem("userid", result.data.UserID)
              navigate("/")
            } else {
              setLogginErrors({ show: true, message: "Invalid UserName or Password" })
              return
            }
          } else {
            setLogginErrors({ show: true, message: "Invalid UserName or Password" })
          }
        }
      ).catch(
        (err) => {
          //console.log(err)
          setLogginErrors({ show: true, message: "Something went wrong please try again. Reason: " + err })
        }
      )
    }
  };

  React.useEffect(
    () => {
      if (localStorage.getItem("userid")) {
        navigate("/Profiles")
      }
    }, []
  )

  const onForgotpasswordClick = () => {
    navigate("/forgotPassword")
  }

  const onregisterClick = () => {
    navigate("/register")
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h4" className="text-bold">
            ASV Mall
          </Typography>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={emailId}
              error={errorCodes.emailId}
              helperText={errorCodes.emailId && errorCodes.emailIdCode}
              onChange={onChangeEmail}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              error={errorCodes.password}
              helperText={errorCodes.password && errorCodes.passwordCode}
              onChange={onPasswordChange}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {logginErrors.show && <p className="text-danger">{logginErrors.message}</p>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link variant="body2" onClick={onForgotpasswordClick}>
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link onClick={onregisterClick} variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}