import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import * as React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Container } from "@mui/material";
import axios from "axios";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';

const defaultTheme = createTheme();
function EditAdressDetails(props) {
    const { updatepage, addressList, addressDetails } = props
    const [show, setShow] = useState(false);
    const [errorCode, setErrorcode] = useState("")
    const [updateUI, setUpdateUI] = useState(true);
    let totalAddressesListLength = 1
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleSubmit = (event) => {
        event.preventDefault();
        if (Array.isArray(addressList) && addressList.length < 1) {
            totalAddressesListLength = 0
        }
        const data = new FormData(event.currentTarget);
        const isValide = validateRegistrationFeilds(data)
        if (isValide) {
            const urlInputs = {
                "main_id": "8989877hhftgh67",
                "user_id": localStorage.getItem("userid"),
                "address_id": addressDetails.address_id,
                "address_name": data.get('addressName'),
                "address_mobile": data.get('mobile'),
                "address_line1": data.get('address1'),
                "address_line2": data.get('address2'),
                "address_city": data.get('city'),
                "address_state": data.get('addressState'),
                "address_zip": data.get('zip'),
                "address_country": data.get('country')
            }
            axios.post("https://asvmall.com/api/address/editaddressdetails.php", urlInputs).then((result) => {

                if (result.status === 200 && result.request.readyState === 4) {
                    alert("Succefully Updated New Address")
                    if (updatepage) {
                        updatepage();
                        setUpdateUI(!updateUI)
                    }
                    window.location.reload();
                }
            }).catch("no")
            handleClose();
        }
    }

    const validateRegistrationFeilds = (data) => {
        if (data.get('addressName').length < 3) {
            setErrorcode("addressName")
            return false
        } else if (data.get('mobile').length != 10) {
            setErrorcode("mobile")
            return false
        } else if (data.get('address1').length < 3) {
            setErrorcode("address1")
            return false
        } else if (data.get('address2').length < 3) {
            setErrorcode("address2")
            return false
        }
        else if (data.get('city').length < 3) {
            setErrorcode("city")
            return false
        } else if (data.get('addressState').length < 3) {
            setErrorcode("addressState")
            return false
        } else if (data.get('country').length < 3) {
            setErrorcode("country")
            return false
        } else if (data.get('zip').length != 6) {
            setErrorcode("zip")
            return false
        }
        else {
            setErrorcode("")
            return true
        }
    }
    return (
        <>
            <EditIcon onClick={handleShow} />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Address</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ThemeProvider theme={defaultTheme}>
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="addressName"
                                                label="Shipment Name"
                                                defaultValue={addressDetails.address_user_name}
                                                name="addressName"
                                                autoComplete="addressName"
                                                error={errorCode === "addressName"}
                                                helperText={errorCode === "addressName" ? "Provide valid name for shipment" : false}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                type="number"
                                                id="mobile"
                                                label="Mobile Number"
                                                name="mobile"
                                                defaultValue={addressDetails.address_mobile}
                                                autoComplete="mobile"
                                                error={errorCode === "mobile"}
                                                helperText={errorCode === "mobile" ? "Provide Valid Mobile number" : false}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="address1"
                                                label="Address Line 1"
                                                name="address1"
                                                defaultValue={addressDetails.address_line_1}
                                                autoComplete="address1"
                                                error={errorCode === "address1"}
                                                helperText={errorCode === "address1" ? "Provide valid address line 1 like street" : false}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                name="address2"
                                                label="Address LIne 2"
                                                id="address2"
                                                defaultValue={addressDetails.address_line_2}
                                                autoComplete="address2"
                                                error={errorCode === "address2"}
                                                helperText={errorCode === "address2" ? "Provide valid address line 2 like landmark" : false}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                name="city"
                                                label="City"
                                                defaultValue={addressDetails.address_city}
                                                id="city"
                                                error={errorCode === "city"}
                                                helperText={errorCode === "city" ? "Please provide your city name" : false}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="addressState"
                                                label="State"
                                                name="addressState"
                                                defaultValue={addressDetails.address_state}
                                                autoComplete="addressState"
                                                error={errorCode === "addressState"}
                                                helperText={errorCode === "addressState" ? "Please provide your State name" : false}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="country"
                                                label="Country"
                                                name="country"
                                                autoComplete="country"
                                                defaultValue={addressDetails.address_country}
                                                error={errorCode === "country"}
                                                helperText={errorCode === "country" ? "Provide valid country " : false}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                required
                                                fullWidth
                                                id="zip"
                                                label="Postal Zip"
                                                name="zip"
                                                defaultValue={addressDetails.address_zip}
                                                type="number"
                                                autoComplete="zip"
                                                error={errorCode === "zip"}
                                                helperText={errorCode === "zip" ? "Provide valid Postal Zip Address" : false}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} className="m-3 ">
                                        <Button variant="secondary" className="me-3 " onClick={handleClose}>
                                            Close
                                        </Button>
                                        <Button variant="primary" type="submit">
                                            Save Changes
                                        </Button>
                                    </Grid>
                                </Box>
                            </Box>
                        </Container>
                    </ThemeProvider>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditAdressDetails;



