import { useNavigate, useParams } from 'react-router-dom'
import './productsbySubCatId.css'
import { useEffect, useState } from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Figure } from 'react-bootstrap';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { ShoppingCart, ShoppingCartCheckout } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import RecipeReviewCard from '../products/products';

const ProductsBysubCatId = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [productsList, setProductsList] = useState([])

  const onEachProductClick = (productDetails) => {
    navigate(`/products/${productDetails.productID}`)
  }

  const getProductsCard = () => {
    if (Array.isArray(productsList) && productsList.length > 0) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} className='d-flex flexwrap'>
            {productsList.map((each,ind) => (
              <Card key={ind} sx={{ maxWidth: '100%' }} onClick={() => onEachProductClick(each)}>
                <Typography  >
                  {each.productName}
                </Typography>
                <Figure className="d-flex justify-content-center">
                  <Figure.Image
                    fluid={false}
                    width={'100%'}
                    height={"100%"}
                    src={each.productImage}
                    alt="Paella dish"
                  />
                </Figure>
                <CardContent>
                  <Typography variant="body2" color="text.dark">
                    <p className='d-flext justify-content-between'> <s>Rs. {each.productPrice} /- </s>  <b>Rs. {each.productOfferPrice} /-</b></p>
                    <span className='text-truncate'>{each.productShortDesc}   </span>
                  </Typography>
                </CardContent>
                <CardActions disableSpacing >
                  <Button className='w-100 ' variant="contained" color="info" startIcon={<ShoppingCart />}>
                    View Product
                  </Button>
                </CardActions>
              </Card>
              // <div className={` products-card  col-4 col-md-4 col-lg-3` } onClick={() =>onEachProductClick(each)} >
              //     {/* <img src={imageUrl} className="product-Img" /> */}
              //     <img className="product-image" src={each.productImage}  alt={each.productName}/>
              //     {/* <img src={ratingsImage} className="rating-stars" /> */}
              //     <h6 className="product-title">{each.productName}</h6>
              //     <h6 className="product-price" >${each.productPrice} <span className="product-offer-price"> ${each.productPrice * 2}</span></h6>
              //     <button className="btn bg-info product-add-cart-button" >
              //         Add to cart
              //     </button>
              // </div>
            ))}
          </Grid>
        </Grid>)
    } else {
      return (<p>products list is empty</p>)
    }
  }

  useEffect(() => {
    axios.post("https://asvmall.com/api/productsbysubcatid.php", {
      "main_id": "8989877hhftgh67",
      "subCat_id": id
    }).then(res => {
      if (Array.isArray(res.data)) {
        setProductsList(res.data)
      }
      else {
        setProductsList([]);
      }
    }).catch((e) => {
    })
  }, [])

  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          {
            productsList.length > 0 ?
              productsList.map((each,ind) => (
                <Grid  key={ind} item xs={12} md={6} sm={12} lg={2}>
                  <RecipeReviewCard productDetails={each} key={each.productID} />
                </Grid>
              ))
              :
              <Grid xs={12} item className="m-3 ">
                <div className=" w-100 d-flex flex-column justify-content-center align-items-center justify-content-center ">
                  <img className="empty-product-image " src="https://i.pinimg.com/originals/6f/fd/64/6ffd64c5366898c59bbc91d9aec935c3.png" />
                  <p>No Products on this sub category...</p>
                </div>
              </Grid>
          }
        </Grid>
      </Box>
    </div>
  )
}

export default ProductsBysubCatId;