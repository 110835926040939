

import axios from 'axios';
import { useEffect, useState } from 'react';
import { Figure } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';

function MyCarosal() {
    const [bannersList, setBannersList] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        axios.post("https://asvmall.com/api/banners/getbanners.php", {
            "main_id": "8989877hhftgh67"
        }).then((res) => {
            if (Array.isArray(res.data)) {
                setBannersList(res.data)
            }
        }).catch(e => {
            //console.log(e)
        })
    }, [])

    return (
        <div>

      
        {bannersList.length >0 && 
        <Carousel >
            {
                bannersList.map((e,ind) => (
                    <Carousel.Item   key={ind}className="p-1 text-center" onClick={() => navigate(`productsbyCatId/${e.cat_id}`)}>
                         <Figure className='m-auto'>
      <Figure.Image
        width={'100%'}
        height={180}
        alt="171x180"
       src={e.carousel_image.startsWith("uploads") ?"https://asvmall.com/api/banners/"+e.carousel_image : e.carousel_image} 
      />
      
      
    </Figure>
                         
                            </Carousel.Item>
                ))
            }
        </Carousel>
          }
          </div>
  
    );
}

export default MyCarosal;