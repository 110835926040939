import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import bcrypt from 'bcryptjs'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignUp() {
  const [errorCode, setErrorcode] = React.useState("")
  const [showErrors, setShowErrors] = React.useState(false)
  const [name, setName] = React.useState("")
  const [lastName, setLastName] = React.useState("")
  const [userName, setUserName] = React.useState("")
  const [universityName, setUniversityName] = React.useState("")
  const [employId, setEmployId] = React.useState("")
  const [contactNumber, setContactNumber] = React.useState("")
  const [emailId, setEmailId] = React.useState("")
  const [password, setPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const [logginErrors, setLogginErrors] = React.useState({
    show: false,
    message: "Invalid UserName or Password"
  })
  const [errorCodes, setErroCodes] = React.useState({
    name: false,
    nameCode: "Name Should have 3 charecters atleast",
    lastName: false,
    lastNameCode: "Name Should have 3 charecters atleast",
    userName: false,
    userNameCode: "User Name Should have 5 charecters atleast",
    universityName: "",
    universityNameCode: "User Name Should have 3 charecters atleast",
    employId: false,
    employIdCode: "User Name Should have 6 charecters atleast",
    contactNumber: false,
    contactNumberCode: "Contact Number should have 10digits",
    emailId: false,
    emailIdCode: "provie valid Email Id",
    password: false,
    passwordCode: "Password should have 6 charecters",
    confirmPassword: false,
    confirmPasswordCode: "confirm password should match with password",
  })

  const navigate = useNavigate();

  const onChangeName = (e) => {
    const nameRegex = /^[a-zA-Z ]+$/; // Allows letters (uppercase and lowercase), spaces, and periods
    // 3. Update state and provide feedback based on validation:
    if (e.target.value == "") {
      setName(e.target.value);
      setErroCodes(prev => ({ ...prev, name: true, nameCode: "Name Should have 3 charecters atleast" }))
    }
    else if (nameRegex.test(e.target.value)) {
      if (e.target.value.length < 3) {
        setName(e.target.value);
        setErroCodes(prev => ({ ...prev, name: true, nameCode: "Name Should have 3 charecters atleast" }))
      }
      else if (e.target.value.length >= 20) {
        setErroCodes(prev => ({ ...prev, name: true, nameCode: "Name Should not greater than 20 charecters" }))
      }
      else {
        setName(e.target.value);
        setErroCodes(prev => ({ ...prev, name: false, nameCode: "" }))
      }
    } else {
      // Provide informative error message or visual feedback (e.g., highlighting the input)
      setErroCodes(prev => ({ ...prev, name: true, nameCode: "Invalid name: Please enter only letters, spaces" }))
    }
  }

  const onChangeLastName = (e) => {
    const nameRegex = /^[a-zA-Z ]+$/; // Allows letters (uppercase and lowercase), spaces, and periods
    // 3. Update state and provide feedback based on validation:
    if (e.target.value == "") {
      setLastName(e.target.value);
      setErroCodes(prev => ({ ...prev, lastName: true, lastNameCode: "Name Should have 3 charecters atleast" }))
    }
    else if (nameRegex.test(e.target.value)) {
      if (e.target.value.length < 3) {
        setLastName(e.target.value);
        setErroCodes(prev => ({ ...prev, lastName: true, lastNameCode: "Name Should have 3 charecters atleast" }))
      }
      else if (e.target.value.length >= 20) {
        setErroCodes(prev => ({ ...prev, lastName: true, lastNameCode: "Name Should not greater than 20 charecters" }))
      }
      else {
        setLastName(e.target.value);
        setErroCodes(prev => ({ ...prev, lastName: false, lastNameCode: "" }))
      }
    } else {
      // Provide informative error message or visual feedback (e.g., highlighting the input)
      setErroCodes(prev => ({ ...prev, name: true, nameCode: "Invalid name: Please enter only letters, spaces" }))
    }
  }

  const onChangeUserName = (e) => {
    const nameRegex = /^[a-zA-Z_0-9]+$/; // Allows letters (uppercase and lowercase), spaces, and periods
    // 3. Update state and provide feedback based on validation:
    if (e.target.value == "") {
      setUserName(e.target.value.toLowerCase());
      setErroCodes(prev => ({ ...prev, userName: true, userNameCode: "User Name Should have 3 charecters atleast" }))
    }
    else if (nameRegex.test(e.target.value)) {
      if (e.target.value.length < 3) {
        setUserName(e.target.value.toLowerCase());
        setErroCodes(prev => ({ ...prev, userName: true, userNameCode: "Name Should have 3 charecters atleast" }))
      }
      else if (e.target.value.length >= 20) {
        setErroCodes(prev => ({ ...prev, userName: true, userNameCode: "User Name Should not greater than 20 charecters" }))
      }
      else {
        setUserName(e.target.value.toLowerCase());
        setErroCodes(prev => ({ ...prev, userName: false, userNameCode: "" }))
      }
    } else {
      // Provide informative error message or visual feedback (e.g., highlighting the input)
      setErroCodes(prev => ({ ...prev, userName: true, userNameCode: "Invalid name: Please enter only letters, spaces" }))
    }
  }

  const onContactNumberChange = (e) => {
    const nameRegex = /^[0-9]+$/; // Allows letters (uppercase and lowercase), spaces, and periods
    // 3. Update state and provide feedback based on validation:
    if (e.target.value == "") {
      setContactNumber(e.target.value);
      setErroCodes(prev => ({ ...prev, contactNumber: true, contactNumberCode: "Contact Number should be 10 digits" }))
    }
    else if (nameRegex.test(e.target.value)) {
      if (e.target.value.length < 10) {
        setContactNumber(e.target.value);
        setErroCodes(prev => ({ ...prev, contactNumber: true, contactNumberCode: "Contact Number should be 10 digits" }))
      }
      else if (e.target.value.length > 10) {
        setErroCodes(prev => ({ ...prev, contactNumber: false, contactNumberCode: "Contact Number should be 10 digits" }))
      }
      else {
        setContactNumber(e.target.value);
        setErroCodes(prev => ({ ...prev, contactNumber: false, contactNumberCode: "" }))
      }
    } else {
      // Provide informative error message or visual feedback (e.g., highlighting the input)
      setErroCodes(prev => ({ ...prev, contactNumber: true, contactNumberCode: "Invalid Number: Please enter only Numbers," }))
    }
  }

  const onChangeEmail = (e) => {
    // 2. Email validation using a stricter regular expression:
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Allows standard email format
    const nameRegex = /^[a-zA-Z0-9@.]*$/;
    const isValidEmail = emailRegex.test(e.target.value);
    // 3. Update state and provide feedback based on validation:
    if (e.target.value === "") {
      setEmailId("");
      setErroCodes((prev) => ({ ...prev, emailId: true, emailIdCode: "Email is required" }));
    } else if (isValidEmail) {
      setEmailId(e.target.value.toLowerCase()); // Convert to lowercase (optional)
      setErroCodes((prev) => ({ ...prev, emailId: false, emailIdCode: "" }));
    } else {
      setEmailId(e.target.value.toLowerCase()); // Convert to lowercase (optional)
      setErroCodes((prev) => ({ ...prev, emailId: true, emailIdCode: "Invalid email format" }));
    }
  };

  const onPasswordChange = (e) => {
    // 3. Update state and provide feedback based on validation:
    if (e.target.value.length < 6) {
      setPassword(e.target.value);
      setErroCodes(prev => ({ ...prev, password: true, passwordCode: "Password Should have atleast 6 charecters" }))
    }
    else if (e.target.value.length > 20) {
      setErroCodes(prev => ({ ...prev, password: true, passwordCode: "Password Should not more than 20 charecters" }))
    }
    else {
      setPassword(e.target.value);
      setErroCodes(prev => ({ ...prev, password: false, passwordCode: "" }))
    }
    if ((e.target.value.length >= 6) && (e.target.value == confirmPassword)) {

      setErroCodes(prev => ({ ...prev, confirmPassword: false, confirmPasswordCode: "" }))
    }
  }

  const onConfirmPasswordChange = (e) => {
    // 3. Update state and provide feedback based on validation:
    if ((e.target.value.length >= 6) && (e.target.value == password)) {
      setConfirmPassword(e.target.value);
      setErroCodes(prev => ({ ...prev, confirmPassword: false, confirmPasswordCode: "" }))
    }
    else {
      setConfirmPassword(e.target.value);
      setErroCodes(prev => ({ ...prev, confirmPassword: true, confirmPasswordCode: "Confirm Password Should match with Password" }))
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const isValide = validateRegistrationFeilds2()
    if (isValide) {
      const AsvCustomSalt = '$2a$10$CwTycUXWue0Thq9StjUM0u'
      const hashedPassword = bcrypt.hashSync(password, AsvCustomSalt)
      const userDeatailsObj = {
        "main_id": "8989877hhftgh67",
        "user_name": userName,
        "user_mail": emailId,
        "user_password": hashedPassword,
        "user_firstName": name,
        "user_lastName": lastName,
        "user_Phone": contactNumber,
        "user_salt": AsvCustomSalt
      }
      axios.post('https://asvmall.com/api/users/addusers.php', userDeatailsObj).then((res) => {
        //if the registration successfull then it will returns 1 from the database so add a flag to check wether it succssfull or not.
        if (res.data === 1) {
          // alert("registratered successfull, Please login ")
          navigate('/Profiles')
        } else {
          setLogginErrors({ show: true, message: "Error Accured " + res.data })
        }
      }).catch((e) => {
        setLogginErrors({ show: true, message: "Error Accured " + e })
      })
    } else {
      return
    }
  };

  const validateRegistrationFeilds2 = () => {
    let isvalid = true;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Allows standard email format
    if (name.length < 3) {
      setErroCodes(prev => ({ ...prev, name: true, }))
      isvalid = false
    }
    if (lastName.length < 3) {
      setErroCodes(prev => ({ ...prev, lastName: true, }))
      isvalid = false
    }
    if (userName.length < 3) {
      setErroCodes(prev => ({ ...prev, userName: true }))
      isvalid = false
    }
    if (contactNumber.length != 10) {
      setErroCodes(prev => ({ ...prev, contactNumber: true, contactNumberCode: "contact Number should have 10 digits " }))
      isvalid = false
    }
    if (!emailRegex.test(emailId)) {
      setErroCodes(prev => ({ ...prev, emailId: true, emailIdCode: "Enter valid Email address" }))
      isvalid = false
    } if (password.length < 6) {
      setErroCodes(prev => ({ ...prev, password: true, passwordCode: "password Should have 6 charecters atleast" }))
      isvalid = false
    } if ((password !== confirmPassword) || (confirmPassword.length < 6)) {
      setErroCodes(prev => ({ ...prev, confirmPassword: true, confirmPasswordCode: "confirm Password Should Match PAssword" }))
      isvalid = false
    }
    return isvalid
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  // error={errorCode === "firstName"}
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={name}
                  error={errorCodes.name}
                  onChange={onChangeName}
                  helperText={errorCodes.name && errorCodes.nameCode}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  value={lastName}
                  error={errorCodes.lastName}
                  onChange={onChangeLastName}
                  helperText={errorCodes.lastName && errorCodes.lastNameCode}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="userName"
                  label="User Name"
                  name="userName"
                  autoComplete="userName"
                  value={userName}
                  error={errorCodes.userName}
                  helperText={errorCodes.userName && errorCodes.userNameCode}
                  onChange={onChangeUserName} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  type="number"
                  id="mobile"
                  label="Mobile Number"
                  name="mobile"
                  value={contactNumber}
                  error={errorCodes.contactNumber}
                  helperText={errorCodes.contactNumber && errorCodes.contactNumberCode}
                  onChange={onContactNumberChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={emailId}
                  error={errorCodes.emailId}
                  helperText={errorCodes.emailId && errorCodes.emailIdCode}
                  onChange={onChangeEmail} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  value={password}
                  error={errorCodes.password}
                  helperText={errorCodes.password && errorCodes.passwordCode}
                  onChange={onPasswordChange} />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="confirm password"
                  type="password"
                  id="password"
                  value={confirmPassword}
                  error={errorCodes.confirmPassword}
                  helperText={errorCodes.confirmPassword && errorCodes.confirmPasswordCode}
                  onChange={onConfirmPasswordChange} />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowExtraEmails" color="primary" />}
                  label="I want to receive inspiration, marketing promotions and updates via email."
                  error={errorCode === "firstName"}
                  helperText={errorCode === "firstName" ? "incorrect First Name" : false}
                />
              </Grid>
            </Grid>
            {logginErrors.show && <p className="text-danger">{logginErrors.message}</p>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/Profiles" variant="body2">
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}