// basic imports
import React, { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import axios from "axios";

//mui imports
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
//components imports
import AddressModal2 from "../profile/addressmodel2";
import './cartlist.css'

function CartList1() {
   const navigate = useNavigate()
   const [cartItems, setCartItems] = useState([]);
   const [userdetails, setUserDetails] = useState({})
   const [updateUi, setUpdateUi] = useState(false);
   const userId = localStorage.getItem("userid");
   const Item = styled(Paper)(({ theme }) => ({
      backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      ...theme.typography.body2,
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
   }));

   const getPriceDetails = () => {

      let totalOfferPrice = cartItems.reduce((sum, product) => sum + (product.count * product.productOfferPrice), 0);
      let totalPrice = cartItems.reduce((sum, product) => sum + (product.count * product.productPrice), 0);
      return (
         <div>
            <p>Price ({cartItems.length}items) Rs.{Math.ceil(totalPrice)}/-</p>
            <p>Discount  Rs.{Math.ceil(totalPrice - totalOfferPrice)}/-</p>
            <hr></hr>
            <h3>Total Amount: Rs.{Math.ceil(totalOfferPrice)}/-</h3>
            <hr></hr>
            <p>you will save  Rs.{Math.ceil(totalPrice - totalOfferPrice)}/- on this order</p>
         </div>
      )
   }

   const onDecrementCount = (e, ItemId, eachCount) => {
      const decreamentCount = parseInt(eachCount) - 1
      postAddtoCartData(e, ItemId, decreamentCount, "Item quantity reduced Successfulled")
   }

   const onIncrementCount = (e, ItemId, eachCount) => {
      const incrementedCount = parseInt(eachCount) + 1
      postAddtoCartData(e, ItemId, incrementedCount, "Item Quantity Added Successfully")
   }

   const postAddtoCartData = (e, ItemId, eachCount, alertText) => {
      e.target.disabled = true
      setUpdateUi(false);
      const requestInputs = {
         "main_id": "8989877hhftgh67",
         "product_id": ItemId,
         "count": eachCount,
         "user_id": localStorage.getItem("userid")
      }
      axios.post("https://asvmall.com/api/updatecartitem.php", requestInputs).then(res => {
         alert(alertText)
         e.target.disabled = false
         setUpdateUi(true);

      }).catch((e) => {
         e.target.disabled = false
      })
   }

   const omRemoveFromCart = (itemId) => {
      alert("removing item from cart")
      sendRemoveCartItemRequest(itemId, userId)
   }

   const sendRemoveCartItemRequest = (productId, userId) => {
      setUpdateUi(false);
      const requestInputs = {
         "main_id": "8989877hhftgh67",
         "product_id": productId,
         "user_id": userId
      }
      axios.post("https://asvmall.com/api/removeCartItem.php", requestInputs).then(res => {
         alert("Item removed Successfully")
         setUpdateUi(true);
      }).catch((e) => {
         //console.log(e)
      })
   }

   const onSaveForLater = (productId) => {
      setUpdateUi(false);
      const requestInputs = {
         "main_id": "8989877hhftgh67",
         "product_id": productId,
         "user_id": userId
      }
      axios.post("https://asvmall.com/api/removeCartItem.php", requestInputs).then(res => {
         axios.post("https://asvmall.com/api/addtosavelater.php", requestInputs).then(res => {
            //   setToastText(" Item removed SuccessFully")
            //   setShow(true)
            setUpdateUi(true);
            alert("Item Added to save for later")

         }).catch((e) => {
            //console.log(e)
         })
         setUpdateUi(true);
      }).catch((e) => {
         //console.log(e)
      })
   }

   React.useEffect(
      () => {
         if (localStorage.getItem("userid")) {
            const urlInputs = {
               "main_id": "8989877hhftgh67",
               "user_id": localStorage.getItem("userid"),
            }
            axios.post('https://asvmall.com/api/cartItems.php', urlInputs).then(res => {
               if (Array.isArray(res.data)) {
                  setCartItems(res.data)
               } else {
                  setCartItems([])
               }
            }).catch(e => {
               alert(e)
            })
            let userId = localStorage.getItem("userid")
            axios.post('https://asvmall.com/api/getuserbyid.php', {
               "main_id": "8989877hhftgh67",
               "user_id": userId
            }).then(res => {
               if (Array.isArray(res.data)) {
                  setUserDetails(res.data[0])
               } else {
                  setUserDetails({})
               }
            }).catch(e => {
               alert(e)
            })
         } else {
            navigate("/login")
         }
      }, [updateUi]
   )

   return (
      <Box style={{ border: "0px", height: '100px' }} sx={{ flexGrow: 1 }}>
         {cartItems.length > 0 ?
            <Grid container spacing={2}>
               <Grid item xs={12} md={8}>
                  <AddressModal2 />
                  <Item sx={{ flexGrow: 1 }}>
                     <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {cartItems.map((eachItem, indexnum) => {
                           return (
                              <ListItem container alignItems="flex-start" key={indexnum}>
                                 <Grid item xs={4} md={3} lg={2}>
                                    <img src={eachItem.productThumb} className="w-100 pb-3" alt="Product thumbnail" />
                                    <ButtonGroup
                                       disableElevation
                                       variant="contained"
                                       aria-label="Disabled button group"
                                       className=" d-sm-none"
                                    >
                                       {eachItem.count > 1 && <Button onClick={(e) => onDecrementCount(e, eachItem.productId, eachItem.count)}>-</Button>}
                                       <Button className='counterChanger'>{eachItem.count}</Button>
                                       <Button onClick={(e) => onIncrementCount(e, eachItem.productId, eachItem.count)} >+</Button>
                                    </ButtonGroup>
                                 </Grid>
                                 <Grid item xs={8} md={9} lg={10} className="p-2 align-center justify-content-center">
                                    <div>
                                       <h4 className="cart-product-title  text-truncate">{eachItem.productName}</h4>
                                       <p className="offer-price" ><s className="original-price" >₹{eachItem.productPrice} </s>  ₹{eachItem.productOfferPrice}&nbsp;&nbsp;<span className="discount-percent" >{Math.floor((parseInt(eachItem.productOfferPrice) / parseInt(eachItem.productPrice)) * 100)}% off</span></p>
                                       <ButtonGroup
                                          className=" d-none  d-sm-block"
                                          disableElevation
                                          variant="contained"
                                          aria-label="Disabled button group"
                                       >
                                          {eachItem.count > 1 && <Button size="small" onClick={(e) => onDecrementCount(e, eachItem.productId, eachItem.count)}  >-</Button>}
                                          <Button size="small" className='counterChanger'>{eachItem.count}</Button>
                                          <Button size="small" onClick={(e) => onIncrementCount(e, eachItem.productId, eachItem.count)}>+</Button>
                                       </ButtonGroup>
                                       <ButtonGroup
                                          className=" ms-3 pt-3 d-sm-none"
                                          disableElevation
                                          variant="contained"
                                          aria-label="Disabled button group"
                                          orientation="vertical"
                                       >
                                          <Button size="small" color="success" onClick={() => onSaveForLater(eachItem.productId)}>Save For Later</Button>
                                          <Button size="small" color="warning" onClick={() => omRemoveFromCart(eachItem.productId)} > <DeleteIcon /> Remove</Button>
                                       </ButtonGroup>
                                       <ButtonGroup
                                          className=" pt-3  d-none d-sm-block "
                                          disableElevation
                                          aria-label="Disabled button group"
                                          variant="contained"
                                       >
                                          <Button color="success" onClick={() => onSaveForLater(eachItem.productId)} >Save For Later</Button>
                                          <Button color="warning" onClick={() => omRemoveFromCart(eachItem.productId)} > <DeleteIcon /> Remove</Button>
                                       </ButtonGroup>
                                    </div>
                                 </Grid>
                              </ListItem>
                           )
                        })
                        }
                     </List>
                  </Item>
               </Grid>
               <Grid item xs={12} md={4}>
                  <Item>
                     <Typography variant="h4" >
                        Price Details
                     </Typography>
                     {getPriceDetails()}
                     <Link to="/payments"><h6 className="text-success">Continue to payment</h6> </Link>
                  </Item>
               </Grid>
            </Grid> :
            <Grid container spacing={2}>
               <Grid item xs={12} md={12}  >
                  <Item className="emptyCart-bg " >
                     <img className="emptyCart-img w-75 " alt="cart icon" src="https://img.freepik.com/free-vector/shopping-cart-realistic_1284-6011.jpg" />
                     <h1>Your cart is empty...</h1>
                  </Item>
               </Grid>
            </Grid>}
      </Box>
   )
}
export default CartList1

