import { useNavigate, useParams } from "react-router-dom";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import './productDetails.css'
import axios from "axios";
import Rating from '@mui/material/Rating';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useEffect, useState } from "react";
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
// import MyImageMag from "./mymagiczoom";
// import MyGallery from "./imageGallery";
import ImageGalleryWithMagicZoon from "./imagegallerywithmagiczoom";
import Slider from "react-slick";
import RecipeReviewCard from "../products/products";
import CircularProgress from '@mui/joy/CircularProgress';
import { Markup } from "interweave";
// const Img = styled('img')({
//   margin: 'auto',
//   display: 'block',
//   // maxWidth: '100%',
//   // maxHeight: '100%',
// });

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff3d47',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

const ProductDetails = (props) => {
  const { productId } = useParams();
  const [productdetails, setProductDetails] = useState({});
  const [similarProducts, setSimilarProducts] = useState([]);
  
  const [productMedia, setProductMedia] = useState([]);
  const [count, setCount] = useState(1)
  const [status, setStatus] = useState("INITIAL")
  const navigate = useNavigate();
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1060,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  function onDecrement() {
    if (count > 1) {
      setCount(prev => (prev - 1))
    }
  }

  function onIncrement() {
    setCount(prev => (prev + 1))
  }

  const postAddtoCartData = () => {

    if (!localStorage.getItem("userid")) {
      navigate('/login')
      return
    }
    const urlInputs = {
      "main_id": "8989877hhftgh67",
      "user_id": localStorage.getItem("userid"),
    }

    axios.post('https://asvmall.com/api/cartItems.php', urlInputs).then(res => {
      let dbcartItems = res.data
      if (!Array.isArray(dbcartItems)) {
        dbcartItems = []
      }
      let ourProductCount = count
      const sameItemsCheck = dbcartItems.filter((mapechItem) => (mapechItem.productId == productId))
      if (sameItemsCheck.length > 0) {
        ourProductCount = parseInt(sameItemsCheck[0].count) + count;
        const requestInputs = {
          "main_id": "8989877hhftgh67",
          "product_id": productId,
          "count": ourProductCount,
          "user_id": localStorage.getItem("userid")
        }
        axios.post("https://asvmall.com/api/updatecartitem.php", requestInputs).then(res => {
          alert("items added to cart successfully")
          navigate('/cart')
        }).catch((e) => {
          alert(e)
        })
      } else {
        const requestInputs = {
          "main_id": "8989877hhftgh67",
          "product_id": productId,
          "count": ourProductCount,
          "user_id": localStorage.getItem("userid")
        }
        axios.post("https://asvmall.com/api/addToCart.php", requestInputs).then(res => {
          alert("items added to cart successfully")
          axios.post("https://asvmall.com/api/saveforlater/removesaveditem.php", {
            "main_id": "8989877hhftgh67",
            "product_id": productId,
            "user_id": localStorage.getItem("userid")
          }).then(res => {
            if (res.data == 1) {
              //console.log("item removed to cart from save for later")
            }
          }).catch((e) => {
            //console.log(e)
          })
          navigate('/cart')
        }).catch((e) => {
          //console.log("Error occured while adding items to cart", e)
        })
      }
    }).catch(e => {
      alert(e)
    })
  }

  useEffect(() => {
    axios.post("https://asvmall.com/api/productsById.php", {
      "main_id": "8989877hhftgh67",
      "product_id": productId
    }).then(res => {
      if (res.data.productDetails) {
        setProductDetails(res.data.productDetails)
      } else {
        setProductDetails({})
      }
      if (res.data.similarProducts) {
        setSimilarProducts(res.data.similarProducts)
      }
      if (res.data.productsMedia) {
        setProductMedia(res.data.productsMedia)
      }
      setStatus("COMPLETE")
    }).catch((e) => {
     
      setStatus("COMPLETE")
    })
  }, [])

  const getProductDetailsJsx = (status, productdetails) => {
    switch (status) {
      case "INITIAL":
        return (
          <Grid container spacing={2} className="d-flex justify-content-center align-items-center">
            <CircularProgress />
          </Grid>)
      case "COMPLETE":
        return (
        <Grid container spacing={2}>
          {
          }
          {Object.keys(productdetails).length != 0 ?
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" component="div">{productdetails.productName} </Typography>
              </Grid>
              <Grid item xs={12} md={6} >
                {/* <MyGallery /> */}
                <ImageGalleryWithMagicZoon productdetails={productdetails} productMedia={productMedia} />
                {/* <Img alt="complex" className="deatails-box" src={productdetails.productImage} /> */}
              </Grid>
              <Grid item xs={12} md={6} sm container>
                <Grid item xs container direction="column" spacing={2}>
                  <Grid item xs>
                    <Grid item xs >
                      <StyledRating
                        name="customized-color"
                        defaultValue={0}
                        precision={1}
                        max={1}
                        icon={<FavoriteIcon fontSize="inherit" />}
                        emptyIcon={<FavoriteBorderIcon fontSize="inherit" />}
                      />
                    </Grid>
                    <Typography gutterBottom variant="subtitle" >
                      <span className="productb-off-price"> ({100 - Math.ceil((parseInt(productdetails.productOfferPrice) / parseInt(productdetails.productPrice)) * 100)}% OFF)</span>
                      Rs.{productdetails.productOfferPrice}
                    </Typography>
                    <Typography gutterBottom variant="subtitle1" component="div">
                      <s className="productb-original-price"> Rs.{productdetails.productPrice}</s>
                    </Typography>
                    {
                      productdetails.product_seller_type == 1 ?
                        <>
                          <Grid item xs={8} className="counter-div">


                            <ButtonGroup
                              disableElevation
                              variant="contained"
                              aria-label="Disabled button group"
                            >
                              {count > 1 && <Button onClick={onDecrement}>-</Button>}
                              <Button className='counterChanger'>{count}</Button>
                              <Button onClick={onIncrement}>+</Button>
                            </ButtonGroup>

                          </Grid>
                          <Grid item xs={4} >

                            <ButtonGroup
                              disableElevation
                              variant="contained"
                              aria-label="Disabled button group"
                              className="pb-2 pt-2"
                            >
                              <Button className="add-to-cart" onClick={postAddtoCartData}>Add to Cart</Button>
                            </ButtonGroup>


                          </Grid>
                        </>
                        :
                        <Grid item xs={8} >
                          <ButtonGroup
                            disableElevation
                            variant="contained"
                            aria-label="Disabled button group"
                            className="pb-2 pt-2"
                          >
                            <Button className="add-to-cart" target="_blank" href={productdetails.product_amz_link}>Buy From Amazon
                            </Button>
                          </ButtonGroup>
                        </Grid>
                    }
                    <Typography variant="body2" gutterBottom>
                      <Typography variant="h6">
                        Product Details
                      </Typography>
                      <Typography variant="h6">
                        Description
                      </Typography>
                      {/* <h6 className="my-labels">Description</h6> */}
                      <Typography variant="caption">
                      <Markup content={productdetails.productLongDesc} /> 
                        
                      </Typography>
                      <Typography variant="h6">
                        Weight
                      </Typography>
                      {/* <h6 className="my-labels"></h6> */}
                      <Typography variant="caption">
                        {productdetails.productWeight}g
                      </Typography>

                      <Typography variant="h6">
                        Seller From
                      </Typography>
                      <Typography variant="caption">
                        {productdetails.productLocation}g
                      </Typography>
                      {/* <h6 className="my-labels">Seller From</h6> */}
                      {/* {productdetails.productLocation}g */}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {similarProducts.length > 0 && 
              <Grid container spacing={2}>
              
                <Grid item xs={12} nowrap={true} zeroMinWidth>
                  <div className="d-flex align-items-end justify-content-between ps-3 pe-3">
                    <h1 className="mb-0">Similar Products</h1>
                    <h6 className="text-success " onClick={() => { navigate(`/productsbySubCatId/${productdetails.productSubCategoryID}`) }}>View All </h6>
                  </div>
                  <hr className="m-0"></hr>
                </Grid>
                <Grid item xs={12} nowrap={true} className="d-flex justify-content-center align-items-center" >
                  <div className=" similar-slider-div  ">
                    <Slider {...settings}>
                      {
                        similarProducts.map(each => (
                          <RecipeReviewCard productDetails={each} key={each.productID} onClick={() => { navigate(`/products/${each.productID}`) }} />
                        ))
                      }
                    </Slider>
                  </div>
                </Grid>
                 
              </Grid>
     }
            </Grid> :
            <Grid item container xs={12}>
              <div className="d-flex  flex-column justify-content-center align-items-center">
                <img className="w-50 " src="https://cdni.iconscout.com/illustration/premium/thumb/product-is-empty-8044872-6430781.png?f=webp" />
                <h1 className="text-center text-danger">No Products Found for the Given URL</h1>
              </div></Grid>}
        </Grid>)
      
      default :
      return (
        <Grid container spacing={2} className="d-flex justify-content-center align-items-center">
          <CircularProgress />
        </Grid>)
    }
  }
  
  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        margin: 'auto',
        flexGrow: 1,
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
      }}
    >
      {
        getProductDetailsJsx(status, productdetails)
      }
    </Paper>
  )
}
export default ProductDetails;




