import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button } from '@mui/material';
import { ShoppingCart, ShoppingCartCheckout } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Figure } from 'react-bootstrap';
import axios from 'axios'
import './products.css'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const imageStyle = {
  objectFit: 'cover',
  width: '100%',
  height: '100%'
  // minHeight: '200px', // Set a minimum height to ensure sufficient space
};

export default function RecipeReviewCard(props) {
  const [expanded, setExpanded] = React.useState(false);
  const navigate = useNavigate();
  const { productDetails } = props;
  const { productName, productRating, productID, productShortDesc, productPrice, productOfferPrice, productImage, id = 1, product_seller_type } = productDetails;
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const onProductCardClick = () => {
    addtoRecentViewedProduct();
  }

  const addtoRecentViewedProduct = () => {
    let recentlyViewdList = localStorage.getItem("recentProductsList")
    if (recentlyViewdList) {
      const newrecentlyViewdList = JSON.parse(recentlyViewdList);
      const existingIndex = newrecentlyViewdList.indexOf(parseInt(productID));
      if (existingIndex !== -1) {
        // Remove the existing id
        newrecentlyViewdList.splice(existingIndex, 1);
      }
      newrecentlyViewdList.unshift(parseInt(productID));
      axios.post("https://asvmall.com/api/advanced/updaterecentlyViewed.php", {
        "main_id": "8989877hhftgh67",
        "user_id": localStorage.getItem("userid"),
        "products_list": JSON.stringify(newrecentlyViewdList)
      }).then(res => {
       
        navigate(`/products/${productID}`)
        window.location.reload();
      }).catch(
        e => {
          
          navigate(`/products/${productID}`)
          window.location.reload();
        }
      )
    }
  }

  return (
    <Card sx={{ maxWidth: '100%', margin: "2px" }} onClick={onProductCardClick}>
      <Typography className="text-truncate pt-3 pb-3 ps-1">
        {productName}
      </Typography>
      <Figure className="d-flex justify-content-center">
        <Figure.Image
          fluid={false}
          width={'100%'}
          //  height={"100%"}
          className="fig-image"
          src={productImage}
          alt={productName}
          style={{ objectFit: 'cover', ratio: 1 / 1 }}
          sx={imageStyle}
        />
      </Figure>
      <CardContent>
        <Typography variant="body2" color="text.dark">
          <p className='d-flext justify-content-between'> <s>Rs. {productPrice} /- </s>  <b>Rs. {productOfferPrice} /-</b></p>
          <span className='text-truncate'>{productShortDesc}   </span>
        </Typography>
      </CardContent>
      <CardActions disableSpacing >
        <Button className='w-100 ' variant="contained" color="info" startIcon={<ShoppingCart />}>
          {/* {product_seller_type == 1 ? "ADD TO Cart" : "Buy from Amazon" } */}
          View Product
        </Button>
      </CardActions>
    </Card>
  );
}