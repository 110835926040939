import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import axios from 'axios';
import RecipeReviewCard from '../products/products';
import OffCanvaExample from './offconvaexample';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const Sellers = () => {
    const [sellerList, setSellerList] = useState([])
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedcategoryId, setSelectedcategoryId] = useState(1)
    const [selectedcategories, setSelectedcategories] = useState([])
    const [currentState, setCurrentState] = useState("LOADING")

    const handleListItemClick = (event, index, each) => {
        setSelectedIndex(index);
        onCategoryClick(each)
    }

    const onCategoryClick = (eachCat) => {
        setSelectedcategoryId(eachCat.seller_id)

        getSubCatItems(eachCat.seller_id);
    }
    const getSubCatItems = (catId) => {
        axios.post('https://asvmall.com/api/sellers/getProductsbysellerid.php', {
            "main_id": "8989877hhftgh67",
            "seller_id": catId
        }).then(result => {
            setSelectedcategories(result.data)
        }).catch(e => {
            // //console.log(e)
        })
    }

    React.useEffect(() => {
        axios.post('https://asvmall.com/api/sellers/getallsellers.php', {
            "main_id": "8989877hhftgh67"
        }).then(result => {
            if (Array.isArray(result.data)) {
                setSellerList(result.data)
                setSelectedIndex(result.data[0].seller_id)
                setSelectedcategoryId(result.data[0].seller_id)
                getSubCatItems(result.data[0].seller_id)
                setCurrentState('SUCCESS')
            } else {
                setCurrentState('EMPTY')
            }
        }).catch(e => {
            // //console.log(e)
            setCurrentState('FAILED')
        })
    }, [])

    switch (currentState) {
        case 'LOADING':
            return (
                <div className="d-flex  align-items-center  justify-content-center text-center">
                    <h1>Loading...</h1>
                </div>
            );
            break;
        case 'SUCCESS':
            return (
                <Box sx={{ flexGrow: 1, padding: 2 }}>
                    <Grid container spacing={2}>

                        <Grid item xs={12} >
                            <OffCanvaExample sellerList={sellerList} selectedIndex={selectedIndex} handleListItemClickprop={handleListItemClick} />
                        </Grid>
                        {/* <Grid item xs={4} md={3} lg={2} sx={{ flexGrow: 1 }}>
                            <List component="nav" aria-label="Categorys" >
                                {sellerList.map((each) =>
        
                                    <ListItemButton
                                        selected={selectedIndex == each.seller_id}
                                        onClick={(event) => handleListItemClick(event, each.seller_id, each)}
        
                                    >
        
                                        <ListItemText primary={each.seller_name} />
                                    </ListItemButton>
                                )}
        
        
                            </List>
                        </Grid> */}
                        <Grid item xs={12} spacing={2} sx={{ flexGrow: 1, }} className="d-flex flex-wrap">
                            {/* <Grid item xs={8} md={9} lg={10} sx={{ flexGrow: 1, }} className="d-flex flex-wrap">   this line will be executed if we provided the sellers in the same page.*/}
                            {
                                selectedcategories.map((each,ind) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={ind}>
                                        <RecipeReviewCard productDetails={each} key={each.productID} />
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Box>
            )
            break;
        case 'FAILED':
            return (
                <div className="d-flex   m-3  justify-content-center text-center">
                    <div>
                        <img className="w-75" src="https://t3.ftcdn.net/jpg/06/47/94/56/360_F_647945631_K1enp9zSJLMLKhzxyArhexgP5xLvDcb6.jpg" alt="server error" />
                        <h1>Internal server error...</h1>
                    </div>
                </div>);
        default:
            return (
                <div className="d-flex   justify-content-center text-center">
                    <div>
                        <img className="w-75 mt-3" src="https://media.istockphoto.com/id/1075374570/vector/coming-soon.jpg?s=612x612&w=0&k=20&c=6W1rSRAoJnxtMSi98mGD7LjiXA3xQMotLn8hJnmXjzI=" alt="No data found" />
                        <h1> Stores are coming Soon.....</h1>
                    </div>
                </div>);
    }
}

export default Sellers