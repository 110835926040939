
import Grid from '@mui/material/Grid';

import emailjs from '@emailjs/browser';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useRef } from 'react';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Navigate, useNavigate } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AddressModal2 from '../profile/addressmodel2';
import PreferrableTimings from '../additionalComponents/preferableTimings';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

const PaymentsComponent = () => {
    const [cartItems, setCartItems] = useState([]);
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState({})
    const [addressDetails, setAddressDetails] = useState({})
    const [prefferedTime, setPrefferedTimes] = useState("t0")
    const userId = localStorage.getItem("userid")
    let totalOfferPrice = 0;

    const getuserDetails = () => {
        axios.post('https://asvmall.com/api/getuserbyid.php', {
            "main_id": "8989877hhftgh67",
            "user_id": userId
        }).then(res => {
            if (Array.isArray(res.data)) {
                setUserDetails(res.data[0])
            } else {
                setUserDetails({})
            }
        }).catch(e => {
            alert(e)
        })
    }

    const getuserAddressDetails = () => {
        axios.post("https://asvmall.com/api/address/getaddresslist.php", {
            "main_id": "8989877hhftgh67",
            "user_id": localStorage.getItem("userid")
        }).then((result) => {
            if (result.status === 200 && result.request.readyState === 4 && Array.isArray(result.data)) {
                let filteredArr = result.data.filter((eachITem => eachITem.address_isdefault == 0))
                if (filteredArr.length > 0) {
                    setAddressDetails(filteredArr[0])
                }
            }
        }).catch(e => {
            alert(e)
        })
    }

    const getCartItemsApi = () => {
        const urlInputs = {
            "main_id": "8989877hhftgh67",
            "user_id": localStorage.getItem("userid"),
        }
        axios.post('https://asvmall.com/api/cartItems.php', urlInputs).then(res => {
            if (Array.isArray(res.data)) {
                setCartItems(res.data)
                if (res.data.length < 1) {
                    alert("Your cart is empty to proceed shopping")
                    navigate("/")
                }
            } else {
                setCartItems([])
                alert("Your cart is empty to proceed shopping")
                navigate("/")
                return
            }
        }).catch(e => {
            alert(e)
        })
    }

    const getPriceDetailsjsx = () => {
        let totalOfferPrice = cartItems.reduce((sum, product) => sum + (product.count * product.productOfferPrice), 0);
        let totalPrice = cartItems.reduce((sum, product) => sum + (product.count * product.productPrice), 0);
        return (
            <div>
                <p>Price ({cartItems.length}items) Rs.{Math.ceil(totalPrice)}/-</p>
                <p>Discount  Rs.{Math.ceil(totalPrice - totalOfferPrice)}/-</p>
                <hr></hr>
                <h3>Total Amount: Rs.{Math.ceil(totalOfferPrice)}/-</h3>
                <hr></hr>
                <p>you will save  Rs.{Math.ceil(totalPrice - totalOfferPrice)}/- on this order</p>
            </div>
        )
    }

    const onRadioButtonChange = (e) => {
        setPrefferedTimes(e.target.value)
    }

    const onPlaceOrder = (e) => {
        totalOfferPrice = cartItems.reduce((sum, product) => sum + (product.count * product.productOfferPrice), 0);
        const orderUrlInputs = {
            "main_id": "8989877hhftgh67",
            "order_user_id": localStorage.getItem("userid"),
            "order_amount": Math.ceil(totalOfferPrice),
            "order_thumbnail": JSON.parse(cartItems[0].productImagesList)[0].product_media_link,
            "order_ship_name": addressDetails.address_user_name,
            "order_ship_address": addressDetails.address_line_1,
            "order_ship_address2": addressDetails.address_line_2,
            "order_city": addressDetails.address_city,
            "order_state": addressDetails.address_state,
            "order_zip": addressDetails.address_zip,
            "order_country": addressDetails.address_country,
            "order_phone": addressDetails.address_mobile,
            "order_fax": "1234569877890",
            "order_shipping": "0",
            "order_tax": "2312",
            "order_email": userDetails.UserEmail,
            "order_tracking_number": "dsdfsf&dgj",
            "order_preferabl_timing": prefferedTime
        }
        axios.post("https://asvmall.com/api/orders/addorder.php", orderUrlInputs).then((res) => {
            const orderId = res.data;
            cartItems.map(eachItem => {
                const orderDetailsUrlInputs = {
                    "main_id": "8989877hhftgh67",
                    "detail_order_id": orderId,
                    "user_id": localStorage.getItem("userid"),
                    "detail_product_id": eachItem.productID,
                    "detail_name": eachItem.productName,
                    "detail_price": eachItem.productOfferPrice,
                    "detail_sku": eachItem.productSKU,
                    "detail_quantity": eachItem.count
                }
                axios.post("https://asvmall.com/api/orderdetails/postorderdetails.php", orderDetailsUrlInputs).then(res => {

                    alert(`Order placed successfull for ${eachItem.productName}`)
                }).catch(e => {
                    //console.log("Error occured while posting order details", e);
                })
                const removecarturlInputs = {
                    "main_id": "8989877hhftgh67",
                    "product_id": eachItem.productId,
                    "user_id": localStorage.getItem("userid")
                }
                axios.post("https://asvmall.com/api/removeCartItem.php", removecarturlInputs).then(res => {
                    navigate('/orders')
                }).catch((e) => {
                    //console.log("Error while removing cart items", e)
                })
            })
        }).catch(e => {
            //console.log("Error occured while adding orders", e)
        })

        var templateParams = {
            user_name: addressDetails.address_user_name,
            user_email: userDetails.UserEmail,
            user_message: "Your Order Placed Successfully!",
            user_feedback: totalOfferPrice,
            totalOfferPrice: totalOfferPrice

        }
        emailjs.send('service_m3n3haj', 'template_seuau5c', templateParams, {
            publicKey: 'KShC9C9vYPFgpet9P',
        })
            .then(
                () => {
                    //console.log('SUCCESS!');
                },
                (error) => {
                    //console.log('FAILED...', error.text);
                },
            );
    }

    useEffect(() => {
        getCartItemsApi();
        getuserDetails();
        getuserAddressDetails();
    }, [])
    
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={8} >
                    <Grid container >
                        <Grid item xs={12} >
                            <Item>
                                <Typography variant="h4" align="left" className="text-success p-3 text-start" component="h2">
                                    Delivery Address
                                </Typography>
                                <AddressModal2 />
                            </Item>
                        </Grid>
                        <Grid item xs={12} >
                            {/* <PreferrableTimings /> */}
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label">Preferrable Timings</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    defaultValue={prefferedTime}
                                    onChange={(e) => { onRadioButtonChange(e) }}
                                >
                                    <FormControlLabel value="t1" control={<Radio />} label="6.AM - 10.AM" />
                                    <FormControlLabel value="t2" control={<Radio />} label="10.AM - 2PM" />
                                    <FormControlLabel value="t3" control={<Radio />} label="2PM - 6PM" />
                                    <FormControlLabel value="t4" control={<Radio />} label="6PM - 10PM" />
                                    <FormControlLabel value="t0" control={<Radio />} label="Any time" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} >
                            <Item> <div className="text-start ">
                                <Typography variant="h4" align="left" className="text-success p-3" component="h2">
                                    Payment Options
                                </Typography>
                                <div className="d-flex  flex-column justify-content-center align-items-center">
                                    <p className="bg-primary-subtle rounded p-2 w-75" >
                                        <input type="radio" name="payment" disabled /> UPI
                                    </p>
                                    <p className="bg-primary-subtle rounded p-2 w-75">
                                        <input type="radio" name="payment" disabled /> Wallets
                                    </p>
                                    <p className="bg-primary-subtle rounded p-2 w-75">
                                        <input type="radio" name="payment" disabled /> Credit/ debit/ ATM card
                                    </p>
                                    <p className="bg-primary-subtle rounded p-2 w-75">
                                        <input type="radio" name="payment" checked /> Cash on Delivery
                                    </p>
                                    <p className="bg-primary-subtle rounded p-2 w-75">
                                        <input type="radio" name="payment" disabled /> EMI
                                    </p>
                                </div>
                            </div></Item>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} >
                    <Item className="flex-grow-1">
                        <h1>Confirm Order</h1>
                        <img className="rounded img-card-top p-2" src="flipflops.jpeg" alt="" />
                        <div className="card-body">
                            {getPriceDetailsjsx()}
                            {Object.keys(addressDetails).length === 0 ? <p className="text-danger">Add Delivery adress to proceed</p> :
                                <button onClick={onPlaceOrder} className="btn btn-warning"> Place Order</button>}
                            {/* </div> */}
                        </div>
                    </Item>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PaymentsComponent;